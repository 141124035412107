<template>
  <div class="person-list">
    <!-- 已经选中的列表 -->
    <div class="person-items" v-for="(t, i) in personList" :key="i">
      <img
        :src="
          t.photo
            ? uploadImgs + t.photo
            : require('../assets/image/task/icon5.png')
        "
      />
      <p>{{ t.name }}</p>
    </div>
    <!-- 选择窗口 -->
    <el-popover
      v-model="isCompleteAssignShow"
      placement="bottom"
      width="400"
      trigger="click"
      popper-class="my-pop-class"
    >
      <div class="popover-box" :style="popoverBoxStyle">
        <div class="popover-title" :style="popoverTitleStyle">
          <div class="popover-left-line" :style="popoverLeftLineStyle"></div>
          请选择参与者
        </div>
        <div class="popover-main" :style="popoverMainStyle">
          <div
            class="popover-item"
            :style="popoverItemStyle"
            v-for="(t, i) in allList"
            :key="t.id"
          >
            <img
              class="item-img"
              :style="popoverItemImgStyle"
              :src="
                t.photo
                  ? uploadImgs + t.photo
                  : require('../assets/image/task/icon5.png')
              "
            />
            <div class="item-other" :style="popoverItemOtherStyle">
              <!-- 参与者多选 -->
              <el-checkbox
                class="checkbox"
                v-model="t.checked"
                @change="handleCheckedChange(i, t, $event)"
              ></el-checkbox>
              <span class="item-name" :style="popoverItemNameStyle">{{
                t.name
              }}</span>
            </div>
          </div>
        </div>
        <div class="popover-footer" :style="popoverFooterStyle">
          <el-button class="ok-button" type="primary" @click="addPopover"
            >确 定</el-button
          >
          <el-button class="no-button" @click="cancelPopover">取 消</el-button>
        </div>
      </div>
      <img
        slot="reference"
        class="add-person-btn"
        src="../assets/image/task/icon4.png"
        @click="addAssign"
      />
    </el-popover>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    // 弹窗状态
    addShow: {
      type: Boolean,
    },
    // 总列表
    list: {
      type: Array,
    },
    // 参与者列表
    workerList: {
      type: Array,
    },
  },
  data() {
    return {
      radioVal: "",
      isCompleteAssignShow: false,
      // 人员分页
      memberTotal: 10, // 总数
      memberPer_page: 10, // 一页个数
      memberCurrent_page: 1, // 当前页
      allList: [],
      // 选中人员
      personList: [],
      selectLength: [],
      // 行内样式
      popoverBoxStyle: {
        display: "flex",
        "flex-direction": "column",
      },
      popoverTitleStyle: {
        "font-size": "18px",
        color: "#000",
        "font-weight": "bold",
        display: "flex",
        "margin-bottom": "20px",
        "align-items": "center",
      },
      popoverLeftLineStyle: {
        "background-color": "#000",
        width: "4px",
        height: "21px",
        "border-radius": "3px",
        "margin-right": "10px",
      },
      popoverMainStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        "flex-wrap": "wrap",
        "overflow-y": "auto",
      },
      popoverItemStyle: {
        display: "flex",
        width: "17.66%",
        "align-items": "center",
        "margin-bottom": "14px",
        "flex-direction": "column",
        "justify-content": "center",
        padding: "0 2px",
      },
      popoverItemImgStyle: {
        width: "42px",
        height: "42px",
        "border-radius": "50%",
        "margin-bottom": "2px",
      },
      popoverItemOtherStyle: {
        width: "100%",
        display: "flex",
        "align-items": "center",
        "justify-content": "center",
      },
      popoverItemNameStyle: {
        "margin-left": "5px",
      },
      popoverFooterStyle: {
        display: "flex",
        width: "100%",
        "justify-content": "space-around",
      },
    };
  },
  watch: {
    // addShow: {
    //   handler() {
    //     this.personList = this.workerList || [];
    //             this.allList = JSON.parse(JSON.stringify(this.list));

    //     if (this.selectLength.length) {
    //       this.selectLength.forEach((item) => {
    //         let indexId = this.allList.findIndex((item2) => {
    //           return item2.id == item.id;
    //         });
    //         this.$set(this.allList[indexId], "checked", true);
    //       });
    //     }
    //     this.personList.forEach((t) => {
    //       let indexId = this.allList.findIndex((item2) => {
    //         return item2.id == t.id;
    //       });
    //       this.$set(this.allList[indexId], "checked", true);
    //       this.selectLength.push(this.allList[indexId]);
    //     });
    //   },
    //   immediate: true,
    // },
    addShow: {
      handler(newVal) {
        if (newVal) {
          this.personList = this.popWorkerList || [];
          this.allList = JSON.parse(JSON.stringify(this.list));
          // if (this.selectLength.length) {
          //   this.selectLength.forEach((item) => {
          //     let indexId = this.allList.findIndex((item2) => {
          //       return item2.id == item.id;
          //     });
          //     this.$set(this.allList[indexId], "checked", true);
          //   });
          // }

          if (this.personList.length) {
            this.personList.forEach((t) => {
              let indexId = this.allList.findIndex((item2) => {
                return item2.id == t.id;
              });
              this.$set(this.allList[indexId], "checked", true);
              this.selectLength.push(this.allList[indexId]);
            });
          }
        } else {
          this.allList = [];
          this.personList = [];
          this.selectLength = [];
        }
      },
      immediate: true,
    },
  },

  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.allList = JSON.parse(JSON.stringify(this.list));
  },
  // destroyed() {
  //   this.personList = [];
  // },
  methods: {
    loadMoreData() {
      if (this.list.length && this.list.length == this.memberTotal) return;
      this.memberPer_page++;
      this.getArticleListApiTime(this.memberPer_page);
    },

    addAssign() {},
    cancelPopover() {
      this.isCompleteAssignShow = false;
    },
    addPopover() {
      this.personList = [];
      this.selectLength.forEach((item) => {
        this.personList.push(item);
      });
      this.$emit("addPopover", this.personList);
      this.isCompleteAssignShow = false;
    },
    handleCheckedChange(index, t, value) {
      this.$set(this.allList[index], "checked", value);
      let indexOfVal = this.selectLength.indexOf(t);
      if (value && indexOfVal == -1) {
        this.selectLength.push(this.allList[index]);
      } else {
        this.selectLength.splice(this.selectLength.indexOf(t), 1);
      }
    },
  },
};
</script>

<style lang="less">
.el-popover.el-popper.my-pop-class {
  padding: 20px !important;
}
.person-list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .person-items {
    width: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #749bc8;
    margin-bottom: 14px;
    margin-right: 10px;

    img {
      width: 42px;
      height: 42px;
      border-radius: 50%;
    }

    p {
      line-height: initial;
      height: auto;
    }
  }
  .add-person-btn {
    cursor: pointer;
    width: 46px;
    height: 46px;
    margin-bottom: 14px;
  }
}
// 单选
.el-radio.checkbox {
  margin-right: 0;
}
</style>
